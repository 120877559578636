import React from "react";
import { StyledHeader } from "../styles/Styles";
import PodList from "../components/PodList";

// Mailchimp
// import RegMailChimp from "../components/RegMailChimp";
// import AdCardOrVideo from "../components/AdCardOrVideo";

export default class SWE extends React.Component {
  render() {
    return (
      <div>
        <div>
          <StyledHeader>Swedens Hottest Podcasts</StyledHeader>
        </div>
        <div>
          <PodList country_name="se" />
        </div>
      </div>
    );
  }
}
