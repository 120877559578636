import React from "react";
// Styled
import styled from "styled-components";
import { AnimateSharedLayout } from "framer-motion";

import { StyledAbout } from "../styles/AboutStyle";
import Toggle from "./Toggle";

// import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const FaqSection = () => {
  return (
    <StyledFaq>
      <h2>
        Any Questions <span>FAQ</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="Can I Tweet about a Podcast?">
          <div className="answer">
            <p>Yes! Its quiet simple actually.</p>
            <p>
              Just click the Twitter Icon{" "}
              <FontAwesomeIcon icon={faTwitter} style={{ color: "#00acee" }} />{" "}
              and a prefilled Twitter post will appear containing all the
              Hashtags and Buzz associated with the podcast you are listening
              to.
            </p>
          </div>
        </Toggle>
        <Toggle title="Can I share a Podcast?">
          <div className="answer">
            <p>Get your friends in on the act.</p>
            <p>
              Click the Share Icon{" "}
              <FontAwesomeIcon icon={faLink} style={{ color: "#ffbe1c" }} /> on
              the Podcast page and the Podcast URL is copied to the clipboard.
            </p>
          </div>
        </Toggle>
        <Toggle title="Can I listen to a Podcast?">
          <div className="answer">
            <p>Yes thats easy.</p>
            <p>
              Find you desired podcast and click on it. This will bring you to a
              detailed page about that podcast. On this page we list episodes,
              hashtags, twitter accounts. Next to each episode there is a play
              button press it and you will be taken to the music player for that
              episode. Press the episode button and you will be able to select
              an episode.
            </p>
          </div>
        </Toggle>
        <Toggle title="How does the Search work?">
          <div className="answer">
            <p>It "filters" the selected chart.</p>
            <p>
              Type anything in the search field and all the information for the
              currently selected chart will be searched. For example if you
              search "barstool" all barstool produced podcasts will be returned
              along with any podcasts that have the word barstool in there text.
            </p>
          </div>
        </Toggle>
        <Toggle title="Will more Countries be added?">
          <div className="answer">
            <p>Yes this is just the beginning!</p>
            <p>
              Don't see your country in the Charts list, feel free to contact us
              and request it.
            </p>
          </div>
        </Toggle>
        <Toggle title="What is the best way to get in contact?">
          <div className="answer">
            <p>Direct Message us on Social Media.</p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </StyledFaq>
  );
};

const StyledFaq = styled(StyledAbout)`
  display: block;
  span {
    display: block;
    color: #ffbe1c;
  }
  h2 {
    font-size: 3rem;
    padding-bottom: 2rem;
    font-weight: lighter;
    // added
    color: white;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%auto;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      font-size: 1.4rem;
      padding: 1rem 0rem;
      // added
      color: white;
    }
  }
`;

export default FaqSection;

//<Toggle title="What is the best way to get in contact?">
// <div className="answer">
//  <p>
//    <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
//    info@podicon.com
//  </p>
//  <p>Or Follow us and Direct Message us on Social Media.</p>
//</div>
// </Toggle> */
