import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Styles
import "../styles/podcard.scss";

export default class PodCardDisc extends Component {
  render() {
    let { artworkUrl, name, category } = this.props.pod;

    // Convert the Name of the Podcast to lowercase and string out spaces
    var podcast_name = name.toLowerCase().replace(/\s+/g, "");

    // Concat Long Categories
    var newCat;
    newCat = category[0];
    if (newCat === "Society & Culture") {
      newCat = "Culture";
    } else if (newCat === "Samhälle och kultur") {
      newCat = "Kultur";
    } else if (newCat === "Natural Sciences") {
      newCat = "Nat Sciences";
    } else if (newCat === "Religion och spiritualitet") {
      newCat = "Rel/Spiritual";
    } else if (newCat === "Religion & Spirituality") {
      newCat = "Rel/Spiritual";
    } else if (newCat === "Underhållningsnyheter") {
      newCat = "Nyheter";
    } else if (newCat === "Personliga dagböcker") {
      newCat = "Dagböcker";
    } else if (newCat === "Alternative Health") {
      newCat = "Alt. Health";
    } else if (newCat === "Amerikansk fotboll") {
      newCat = "Am. fotboll";
    } else if (newCat === "American Football") {
      newCat = "Am. Football";
    } else if (newCat === "Animation & Manga") {
      newCat = "Anim/Manga";
    } else if (newCat === "Education for Kids") {
      newCat = "Ed. Kids";
    } else if (newCat === "Entertainment News") {
      newCat = "Ent. News";
    } else if (newCat === "Comedy Interviews") {
      newCat = "Comedy";
    } else if (newCat === "Nyhetskommentarer") {
      newCat = "Nyhet";
    } else if (newCat === "Personliga dagböcker") {
      newCat = "Dagböcker";
    } else if (newCat === "Personal Journals") {
      newCat = "Journals";
    } else if (newCat === "Platser och resor") {
      newCat = "Resor";
    } else if (newCat === "Samhällsvetenskap") {
      newCat = "Vetenskap";
    } else if (newCat === "Alternativ hälsa") {
      newCat = "Alt. Hälsa";
    } else if (newCat === "Entrepreneurship") {
      newCat = "Entrepreneur";
    } else if (newCat === "Fashion & Beauty") {
      newCat = "Fashion/Beauty";
    } else if (newCat === "Health & Fitness") {
      newCat = "Health";
    } else if (newCat === "Hälsa och motion") {
      newCat = "Hälsa";
    } else if (newCat === "Komediintervjuer") {
      newCat = "Komedi";
    } else if (newCat === "Mode och skönhet") {
      newCat = "Mode/Skönhet";
    } else if (newCat === "Music Commentary") {
      newCat = "Music";
    } else if (newCat === "Music Interviews") {
      newCat = "Music Int.";
    } else if (newCat === "Self-Improvement") {
      newCat = "Wellbeing";
    } else if (newCat === "Stories for Kids") {
      newCat = "For Kids";
    }

    return (
      <div>
        <Card
          className="mb-3"
          border="0"
          style={{ background: "#1b1b1b", color: "white" }}
        >
          <Link to={{ pathname: `/${podcast_name}/${this.props.pod.id}` }}>
            <div className="img-container">
              <div className="image">
                <Card.Img width="100%" src={artworkUrl} alt="Podcast" />
                <div className="middle">
                  <FontAwesomeIcon icon={faPlayCircle} size="5x" />
                </div>
              </div>
            </div>
          </Link>
          <Card.Text className="mx-1 my-1">
            <div className="alignleft">{newCat}</div>
          </Card.Text>
        </Card>
      </div>
    );
  }
}
