import styled from "styled-components";
import { motion } from "framer-motion";

// Styled Components
export const StyledAbout = styled(motion.div)`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1b1b1b;
  // padding: 1rem 1rem;
  @media (max-width: 1300px) {
    display: block;
    padding: 2rem 2rem;
    text-align: center;
  }
`;

export const StyledDescription = styled.div`
  flex: 1;
  padding-right: 3rem;
  z-index: 2;
  h2 {
    font-weight: lighter;
    font-size: 3rem;
    // added
    color: #eeeeee;
  }
  h3 {
    color: #e0e0e0;
  }
  h4 {
    font-weight: bold;
    font-size: 2rem;
    // added
    color: #bdbdbd;
  }
  span {
    font-weight: bold;
    color: #ffbe1c;
  }
  a {
    font-size: 1.1.rem;
    // added
    color: #bdbdbd;
  }
  p {
    padding: 3rem 0rem;
    color: #ccc;
    font-size: 1.4rem;
    line-height: 150%normal;
    // added
    color: #bdbdbd;
  }

  @media (max-width: 1300px) {
    padding: 0;
    button {
      margin: 2rem 0rem 5rem 0rem;
    }
  }
`;

export const StyledImage = styled.div`
  flex: 1;
  overflow: hidden;
  z-index: 2;
  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }
`;

export const StyledHide = styled.div`
  overflow: hidden;
`;
