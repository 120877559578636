import styled from "styled-components";
import { motion } from "framer-motion";

// Styled Components
export const StyledButton = styled(motion.div)`
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10vh;
`;

// display: flex;
// align-items: center;
// justify-content: space-between;
// @media (max-width: 1300px) {
//   display: block;
//   padding: 2rem 2rem;
//   text-align: center;
