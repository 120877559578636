import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  font-family: 'Lato', sans-serif;
  letter-spacing: .3px;
  // background-color: #122822;
  // background-color: black;
  // background-color: #333a40;
  // added
  background-color: #121212;

}
`;

export default GlobalStyle;
