import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconButton } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPodcast, faLink } from "@fortawesome/free-solid-svg-icons";

const Slider = ({ libraryStatus, setLibraryStatus }) => {
  // the url of the Podcast being tweeted
  // changed from var to const
  var url = window.location.href;
  url = url.replace("https://", "");

  return (
    <nav className="slider-nav">
      <CopyToClipboard text={url}>
        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#ffbe1c" }}
          size="medium"
        >
          <FontAwesomeIcon icon={faLink} />
        </IconButton>
      </CopyToClipboard>
      <button
        className="episode-button"
        onClick={() => setLibraryStatus(!libraryStatus)}
        style={{ color: "#ffbe1c" }}
      >
        Episodes <FontAwesomeIcon icon={faPodcast}></FontAwesomeIcon>
      </button>
    </nav>
  );
};

export default Slider;
