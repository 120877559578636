import React from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import PodCard from "./PodCard";
// import { TagCloud } from "react-tagcloud";

export default class PodList extends React.Component {
  state = {
    loading: true,
    podlist: [],
    tagdata: [],
    searchPod: [],
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  async componentDidMount() {
    // const url = `https://podboard100.com/backend-data/${this.props.country_name}.json`;
    const url = `/backend-data/${this.props.country_name}.json`;
    const response = await fetch(url);
    const data = await response.json();
    this.setState({ podlist: data.items, loading: false });
    this.setState({ tagdata: data.global.hashtags });

    // comment this line to switch to the above code
    // const data = { results };
    // this.setState({ podlist: data.results, loading: false });
  }

  handleChange = (e) => {
    this.setState({ searchPod: e.target.value.toLowerCase() });
    // console.log(e.target.value);
  };

  handleHash = (tag) => {
    let a = tag.substring(1).toLowerCase();
    this.setState({ searchPod: a });
    window.scrollTo(0, this.scrollRef.current.offsetTop, {
      behavior: "smooth",
      block: "start",
    });
  };

  handleClear = () => {
    this.setState({ searchPod: "" });
  };

  render() {
    if (this.state.loading) {
      return <div style={{ color: "#bdbdbd" }}>loading...</div>;
    }

    if (!this.state.podlist) {
      return <div>Podlist not loaded...</div>;
    }

    //   const isMobile = window.innerWidth <= 768;

    // this is the massive Filter Function
    let filteredPod = this.state.podlist.filter((pod) => {
      return (
        (pod.title
          ? pod.title.toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.name
          ? pod.name.toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.artist
          ? pod.artist.toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.summary
          ? pod.summary.toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.category[0]
          ? pod.category[0].toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.category[1]
          ? pod.category[1].toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.category[2]
          ? pod.category[2].toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.category[3]
          ? pod.category[3].toLowerCase().includes(this.state.searchPod)
          : null) ||
        (pod.category[4]
          ? pod.category[4].toLowerCase().includes(this.state.searchPod)
          : null) ||
        (Object.keys(pod.social_media.buzz).toString()
          ? Object.keys(pod.social_media.buzz)
              .toString()
              .toLocaleLowerCase()
              .includes(this.state.searchPod)
          : null) ||
        (pod.social_media.official.twitter_accounts[0]
          ? pod.social_media.official.twitter_accounts[0]
              .toLowerCase()
              .includes(this.state.searchPod)
          : null)
      );
    });

    let podCards = filteredPod.map((pod) => {
      return (
        <Col key={pod.id} xs="6" md="4" lg="2" className="px-1">
          <PodCard pod={pod} editorial={this.props.editorial} />
        </Col>
      );
    });

    return (
      <Container>
        {/* {isMobile ? null : (
          <Row className="mx-1">
            <TagCloud
              minSize={16}
              maxSize={30}
              tags={this.state.tagdata}
              onClick={(tag) => this.handleHash(tag.value)}
            />
          </Row>
        )} */}
        <Row>
          <InputGroup className="mx-3 my-3">
            <FormControl
              type="text"
              placeholder="Search the list..."
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={this.state.searchPod}
              onChange={this.handleChange}
            />
            <InputGroup.Append>
              <Button variant="warning" onClick={this.handleClear}>
                Clear
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Row>
        <Row ref={this.scrollRef}>{podCards}</Row>
      </Container>
    );
  }
}
