import React from "react";
import { StyledHeader } from "../styles/Styles";
import PodList from "../components/PodList";

// Mailchimp
// import RegMailChimp from "../components/RegMailChimp";
// import AdVideo from "../components/AdVideo";

export default class US extends React.Component {
  render() {
    return (
      <div>
        <div>
          <StyledHeader>Americas Hottest Podcasts</StyledHeader>
        </div>
        <div>
          <PodList country_name="us" />
        </div>
      </div>
    );
  }
}
