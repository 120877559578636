import React from "react";

const LibrarySong = ({ currentPod, episode, setCurrentEpisode }) => {
  const episodeSelectHandler = () => {
    setCurrentEpisode(episode);
  };

  return (
    <div onClick={episodeSelectHandler} className="library-song">
      <img
        src={episode.image === null ? currentPod.artworkUrl : episode.image}
        alt={currentPod.name}
      ></img>
      <div className="song-description">
        <h4>{episode.title}</h4>
      </div>
    </div>
  );
};

export default LibrarySong;
