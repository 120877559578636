import React from "react";
import LibrarySong from "./LibrarySong";

const Library = ({ currentPod, setCurrentEpisode, libraryStatus }) => {
  return (
    <div className={`library ${libraryStatus ? "active-library" : ""}`}>
      <h2 className="library-title">Episodes</h2>
      <div className="library-songs">
        {currentPod.episodes.map((episode) => (
          <LibrarySong
            currentPod={currentPod}
            episode={episode}
            setCurrentEpisode={setCurrentEpisode}
            key={episode.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Library;
