import React from "react";
import { Container } from "react-bootstrap";
import { H3 } from "../styles/Styles";

export default class NoMatch extends React.Component {
  render() {
    return (
      <Container>
        <H3>Page Not Found...</H3>
      </Container>
    );
  }
}
