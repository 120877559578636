import React from "react";
import GlobalStyle from "../src/styles/GlobalStyle";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Analytics from "react-router-ga";

import { Layout } from "./components/Layout";
import { NavigationBar } from "./components/NavigationBar";

import US from "./screens/US";
import CAN from "./screens/CAN";
import UK from "./screens/UK";
import AUS from "./screens/AUS";
import IND from "./screens/IND";
import SWE from "./screens/SWE";
import DISC from "./screens/DISC";
import KIDS from "./screens/KIDS";
import SPORTS from "./screens/SPORTS";
import BehindThePod from "./screens/BehindThePod";

import About from "./screens/About";
import Register from "./screens/Register";
import NoMatch from "./screens/NoMatch";
import PodDetail from "./screens/PodDetail";
import PodcastPlayer from "./screens/PodcastPlayer";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Analytics id="UA-181729285-2">
            <NavigationBar />
            <Layout>
              <Switch>
                <Route exact path="/" component={US} />
                <Route exact path="/us" component={US} />
                <Route exact path="/ca" component={CAN} />
                <Route exact path="/gb" component={UK} />
                <Route exact path="/au" component={AUS} />
                <Route exact path="/in" component={IND} />
                <Route exact path="/se" component={SWE} />
                <Route exact path="/discovery" component={DISC} />
                <Route exact path="/sports" component={SPORTS} />
                <Route exact path="/kids" component={KIDS} />
                <Route exact path="/behindthepod" component={BehindThePod} />
                <Route exact path="/about" component={About} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/:podcast_name/:id" component={PodDetail} />
                <Route
                  exact
                  path="/:podcast_name/player/:id"
                  component={PodcastPlayer}
                />
                <Redirect from="/*" to="/us" />
                <Route component={NoMatch} />
              </Switch>
            </Layout>
            <GlobalStyle />
          </Analytics>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;

// <Route exact path="/:cc/player/:id" component={PodcastPlayer} />
