import React from "react";
// Styled
import styled from "styled-components";
import { StyledAbout } from "../styles/AboutStyle";

// import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisterStorySection = () => {
  return (
    <StyledStory>
      <h2>Why?</h2>
      <div className="faq-line"></div>
      <p>
        <span>Podboard100</span> is giving podcast creators the world over this
        unique opportunity to become a part of the Podboard100 by appearing on
        our Discovery chart. Podboard100 uses its own unique social media
        metric, monitoring the use of
        <span>#</span>'s and <span>@</span>'s associated to your podcast. The
        more you and other people talk about your pod on socials, the more our
        metric comes in to play.
        <br />
        So Register and get tweeting today!
      </p>
      <br />
      <h2>How?</h2>
      <div className="faq-line"></div>
      <p>
        Its easy, just enter <span>YOUR</span> Podcast details.
        <br />
        Are you the next <span>Joe</span>?
      </p>
      <br />
    </StyledStory>
  );
};

// Try and add styled div here and dont inherite the motion from StyledAbout
const StyledStory = styled(StyledAbout)`
  display: block;
  span {
    // display: block;
    color: #ffbe1c;
  }
  h2 {
    font-size: 3rem;
    padding-bottom: 2rem;
    font-weight: lighter;
    // added
    color: white;
  }
  p {
    padding: 1rem 0rem;
    font-size: 1.4rem;
    line-height: 150%normal;
    // added
    color: white;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%auto;
  }
`;

export default RegisterStorySection;
