import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
// import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";

// faYoutube,

// const Styles = styled.div`
//   .navbar {
//     background-color: #ea80fc;
//   }
//   a,
//   .navbar-brand,
//   .navbar-nav .nav-link {
//     color: #212121;
//     &:hover {
//       color: white;
//     }
//   }
// `;

//     background-color: #33cccc; #5cdb95
// expand={'sm' | 'md' | 'lg' | 'xl'}

export const NavigationBar = () => (
  <Navbar expand="md" bg="dark" variant="dark">
    <Navbar.Brand href="/">
      <img
        src="/pb100logo.png"
        width="200"
        height="30"
        className="d-inline-block align-top"
        alt="Podboard100"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        <NavDropdown variant="dark" title="CHARTS" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/us">
            AMERICA
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/ca">
            CANADA
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/gb">
            UK
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/au">
            AUSTRALIA
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/in">
            INDIA
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/se">
            SWEDEN
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/discovery">
            DISCOVERY
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown variant="dark" title="GENRE" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/sports">
            SPORTS
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/kids">
            KIDS
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={Link} to="/register">
          REGISTER
        </Nav.Link>
        <Nav.Link as={Link} to="/discovery">
          DISCOVERY
        </Nav.Link>
        <Nav.Link as={Link} to="/about">
          ABOUT
        </Nav.Link>
        <Nav.Link target="_blank" href="https://mobile.twitter.com/podboard100">
          <FontAwesomeIcon icon={faTwitter} />
        </Nav.Link>
        <Nav.Link target="_blank" href="https://www.instagram.com/podboard100/">
          <FontAwesomeIcon icon={faInstagram} />
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

//     <Navbar.Brand href="/">PodBoard100</Navbar.Brand>

// <Nav.Link as={Link} to="/behindthepod">
// BEHIND THE POD
// </Nav.Link>

// <Nav.Link
//  target="_blank"
//  href="https://www.youtube.com/channel/UCIhsoA10V5LEOFUN7fCNh8Q">
//  <FontAwesomeIcon icon={faYoutube} />
// </Nav.Link>;
