import React from "react";
// Styled
import styled from "styled-components";
import { StyledAbout } from "../styles/AboutStyle";

// import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StorySection = () => {
  return (
    <StyledStory>
      <h2>Why?</h2>
      <div className="faq-line"></div>
      <p>
        Noticing that almost all of the podcast rankings out there are simply
        copies of the iTunes chart we felt something needed to be done for two
        reasons.
      </p>
      <p>
        <span>One </span> There is a lot more data out there than just iTunes.
        People are consuming podcasts on all sorts of platforms at an ever
        increasing rate. So we felt we needed to cross reference multiple data
        sources to get a more accurate reflection on trending podcasts.
      </p>
      <p>
        <span>Two </span>Legacy subscribers. How many times have you subscribed
        to a podcast listened for a few weeks then started listening to a new
        series? Well you most likely didn't unsubscribe you just stopped
        listening, the charts still count you as a listener, so who is really
        listening and how can we know for sure people really are?
      </p>
      <br />
      <h2>How?</h2>
      <div className="faq-line"></div>
      <p>
        Podboard100 uses its own unique social media metric, monitoring the use
        of <span>#</span>'s and <span>@</span>'s associated to specific
        podcasts. The more people that are talking about the pods on socials,
        the more our metric comes in to play and bolsters their position on
        Podboard100. <br />
        Is our chart perfect? No. Is it the best chart to see which podcasts are
        trending each day? Absolutely. <br />
        So get tweeting directly from podboard, while you are listening to you
        pods on the podboard and help your favorite podcasters rise up the
        charts.
      </p>
      <br />
    </StyledStory>
  );
};

// Try and add styled div here and dont inherite the motion from StyledAbout
const StyledStory = styled(StyledAbout)`
  display: block;
  span {
    // display: block;
    color: #ffbe1c;
  }
  h2 {
    font-size: 3rem;
    padding-bottom: 2rem;
    font-weight: lighter;
    // added
    color: white;
  }
  p {
    padding: 1rem 0rem;
    font-size: 1.4rem;
    line-height: 150%normal;
    // added
    color: white;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%auto;
  }
`;

export default StorySection;

/* <p>
        Part of the Podicon family, to find out more visit{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://podicon.com"
          style={{ color: "#222326" }}
        >
          <FontAwesomeIcon className="mr-1" icon={faExternalLinkSquareAlt} />
          podicon.com
        </a>
      </p> */
