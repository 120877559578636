import React from "react";
import home1 from "../assets/images/register.jpg";
import {
  StyledAbout,
  StyledDescription,
  StyledImage,
  StyledHide,
} from "../styles/RegisterStyle";

// Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../styles/animation";
// import Wave from "./Wave";

const AboutSection = () => {
  return (
    <StyledAbout>
      <StyledDescription>
        <motion.div>
          <StyledHide>
            <motion.h2 variants={titleAnim}>Want to grow</motion.h2>
          </StyledHide>
          <StyledHide>
            <motion.h2 variants={titleAnim}>your podcast</motion.h2>
          </StyledHide>
          <StyledHide>
            <motion.h2 variants={titleAnim}>
              <span>AUDIENCE?</span>
            </motion.h2>
          </StyledHide>
        </motion.div>
        <motion.p variants={fade}>Register your podcast today.</motion.p>
      </StyledDescription>
      <StyledImage>
        <motion.img variants={photoAnim} src={home1} alt="rock and roll" />
      </StyledImage>
    </StyledAbout>
  );
};

export default AboutSection;
