import React, { useState, useEffect } from "react";
// import Styles
import "../styles/podcastplayer.scss";

import NPlayer from "../components/podcastplayer/NPlayer";
import Song from "../components/podcastplayer/Song";
import Library from "../components/podcastplayer/Library";
import Slider from "../components/podcastplayer/Slider";

function PodcastPlayer(props) {
  useEffect(() => {
    fetchItem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // State
  const [data, setData] = useState([]);
  const [currentEpisode, setCurrentEpisode] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [libraryStatus, setLibraryStatus] = useState(false);

  const fetchItem = async () => {
    //const fetchItem = await fetch(`/backend-data/se/284610981.json`);
    const fetchItem = await fetch(
      `/backend-data/pod/${props.match.params.id}.json`
    );
    const data = await fetchItem.json();
    // const fetchItems = await fetch(`/backend-data/${cc}/${this.props.match.params.id}.json`);
    // gotta setItems to the items array which is episodes
    setData(data);
    // The props.location.state.episodeId is being passed in as react-router props from
    // PodDetail. In PodDetail this is the array index number
    // setCurrentEpisode(data.episodes[0]);
    // setCurrentEpisode(data.episodes[props.location.state.episodeId]);
    // But alas the props.location.state is undefined when we copy paste the player link hence
    // need to set the item to the first podcast when copy/paste link
    let p_item =
      typeof props.location.state === "undefined"
        ? 0
        : props.location.state.episodeId;

    setCurrentEpisode(data.episodes[p_item]);
    setIsLoading(false);
  };

  // Adding this big of State will make the App wait until the data is loaded
  // and you will not get an undefined error when truing to access episodes[0] for example.
  if (isLoading) {
    return <div style={{ color: "#bdbdbd" }}>loading...</div>;
  }

  return (
    <div className={`App ${libraryStatus ? "library-active" : ""}`}>
      <Slider
        libraryStatus={libraryStatus}
        setLibraryStatus={setLibraryStatus}
      />
      <Song currentPod={data} currentEpisode={currentEpisode} />
      <NPlayer
        currentPod={data}
        currentEpisode={currentEpisode}
        setCurrentEpisode={setCurrentEpisode}
      />
      <Library
        currentPod={data}
        setCurrentEpisode={setCurrentEpisode}
        libraryStatus={libraryStatus}
      />
    </div>
  );
}

export default PodcastPlayer;
