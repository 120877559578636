import React from "react";
// Page Components
import RegisterSection from "../components/RegisterSection";
import RegisterStorySection from "../components/RegisterStorySection";
import RegisterButton from "../components/RegisterButton";
// import FaqSection from "../components/FaqSection";
// import StorySection from "../components/StorySection";

// Animations
import { motion } from "framer-motion";
import { PageAnimation } from "../styles/animation";

const About = () => {
  return (
    <motion.div
      variants={PageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <RegisterSection />
      <RegisterStorySection />
      <RegisterButton />
    </motion.div>
  );
};

export default About;
