import React from "react";

const Song = ({ currentPod, currentEpisode }) => {
  return (
    <div className="song-container">
      <img src={currentPod.artworkUrl} alt={currentPod.name}></img>
      <h2 style={{ color: "#eeeeee" }}>{currentPod.name}</h2>
      <h3 style={{ color: "#e0e0e0" }}>{currentEpisode.title}</h3>
    </div>
  );
};

export default Song;
