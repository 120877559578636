import React from "react";
import { Button } from "react-bootstrap";
import { StyledButton } from "../styles/ButtonStyle";

const RegisterButton = () => {
  return (
    <StyledButton>
      <Button
        variant="danger"
        size="lg"
        target="_blank"
        // noauth
        href="https://fssj7s2jdxkj6ff-podboard.adb.eu-amsterdam-1.oraclecloudapps.com/ords/f?p=103"
        // auth
        // href="https://fssj7s2jdxkj6ff-podboard.adb.eu-amsterdam-1.oraclecloudapps.com/ords/r/podboard/podcaster/"
      >
        Register Today
      </Button>
    </StyledButton>
  );
};

export default RegisterButton;
