import React from "react";
import home1 from "../assets/images/about.png";
import {
  StyledAbout,
  StyledDescription,
  StyledImage,
  StyledHide,
} from "../styles/AboutStyle";

// Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../styles/animation";
// import Wave from "./Wave";

const AboutSection = () => {
  return (
    <StyledAbout>
      <StyledDescription>
        <motion.div>
          <StyledHide>
            <motion.h2 variants={titleAnim}>We are changing </motion.h2>
          </StyledHide>
          <StyledHide>
            <motion.h2 variants={titleAnim}>
              the way <span>podcasts</span> are charted one
            </motion.h2>
          </StyledHide>
          <StyledHide>
            <motion.h2 variants={titleAnim}>
              <span>hashtag</span> at a time.
            </motion.h2>
          </StyledHide>
        </motion.div>
        <motion.p variants={fade}>
          Contact us with any podcast related ideas you have. We are happy to
          listen.
        </motion.p>
      </StyledDescription>
      <StyledImage>
        <motion.img variants={photoAnim} src={home1} alt="shhh secret" />
      </StyledImage>
    </StyledAbout>
  );
};

export default AboutSection;
