import React from "react";
import { StyledHeader } from "../styles/Styles";
import PodListDisc from "../components/PodListDisc";

// Mailchimp
// import RegMailChimp from "../components/RegMailChimp";

export default class DISC extends React.Component {
  render() {
    return (
      <div>
        <div>
          <StyledHeader>Find your new favourite Podcast</StyledHeader>
        </div>
        <div>
          <PodListDisc country_name="xx" />
        </div>
      </div>
    );
  }
}
