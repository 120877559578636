import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { faLink, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TwitterTimelineEmbed, TwitterFollowButton } from "react-twitter-embed";
// import Styles
import { H2, H3, P, NBP, HR } from "../styles/Styles";
import "../styles/poddetail.scss";

// import AdVideo from "../components/AdVideo";

export default class PodDetail extends React.Component {
  state = {
    loading: true,
    episodelist: [],
  };

  async componentDidMount() {
    const e_url = `/backend-data/pod/${this.props.match.params.id}.json`;
    const response = await fetch(e_url);
    const data = await response.json();

    this.setState({ episodelist: data, loading: false });
  }

  render() {
    if (this.state.loading) {
      return <div style={{ color: "#bdbdbd" }}>loading...</div>;
    }

    if (!this.state.episodelist) {
      return <div>Episodes not loaded...</div>;
    }
    let {
      id,
      artworkUrl,
      name,
      artist,
      summary,
      social_media,
      exclusive,
      spotify_show,
      youtube_playlist_id,
    } = this.state.episodelist;

    // Tweet Section
    // Build out the tweet intent add #podboard100
    // var hash_list = social_media.official.hashtags.toString() + ",podicon";
    var hash_list = social_media.official.hashtags.toString();

    // var buzz_list = Object.keys(this.props.pod.social_media.buzz).toString();

    // work out if I should add @acc @acc to tweet
    var acc_list;
    if (social_media.official.twitter_accounts.length !== 0) {
      acc_list = social_media.official.twitter_accounts.map((i) => "%40" + i);
      acc_list = acc_list.toString();
      acc_list = acc_list.toString().replace(",", "%20");
    } else {
      acc_list = "";
    }

    // the url of the Podcast being tweeted
    var url = window.location.href;
    url = url.replace("https://", "");

    let twitter_href =
      "https://mobile.twitter.com/intent/tweet?hashtags=" +
      hash_list +
      "&text=" +
      acc_list +
      "&via=PodBoard100&ref_src=twsrc%5Etfw" +
      "&url=" +
      url;

    // End Tweet Section

    // twitter_handle is used on the Twitter plugins
    var twitter_handle;

    if (social_media.official.twitter_accounts[0] == null) {
      twitter_handle = "PodBoard100";
    } else {
      twitter_handle = social_media.official.twitter_accounts[0];
    }

    // Spotify URL
    const spotify_url = `https://open.spotify.com/embed-podcast/show/${spotify_show}`;
    // Youtube URL
    const youtube_url = `https://www.youtube.com/embed/videoseries?list=${youtube_playlist_id}`;

    // change the background color from white to #1b1b1b
    return (
      <Container style={{ background: "#1b1b1b" }}>
        <Row xs={1} md={2} className="my-3">
          <Col lg={4}>
            <Image src={artworkUrl} rounded fluid />
          </Col>
          <Col lg={8}>
            <Row className="mx-0 my-1">
              <H2>{name}</H2>
            </Row>
            <Row className="mx-0">
              <P>{summary}</P>
            </Row>
            {this.state.episodelist.social_media.official.twitter_accounts[0] ==
            null ? null : (
              <Row className="mx-0">
                {this.state.episodelist.social_media.official.twitter_accounts.map(
                  (twitter_acc, i) => (
                    <TwitterFollowButton
                      key={i}
                      screenName={twitter_acc}
                      options={{ size: "large" }}
                    />
                  )
                )}
              </Row>
            )}
            {this.state.episodelist.social_media.official.hashtags[0] ==
            null ? null : (
              <Row className="mx-0">
                <NBP>
                  {this.state.episodelist.social_media.official.hashtags.map(
                    (hashtags, i) => (
                      <span key={i}> #{hashtags} </span>
                    )
                  )}
                </NBP>
              </Row>
            )}
            <Row className="mx-0">
              <NBP>
                <span>{artist}</span>
                <span>
                  <IconButton
                    href={twitter_href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#00acee" }}
                    size="medium"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </IconButton>
                </span>
                <span>
                  <CopyToClipboard text={url}>
                    <IconButton
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#ffbe1c" }}
                      size="medium"
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </IconButton>
                  </CopyToClipboard>
                </span>
              </NBP>
            </Row>
            <Row className="mx-0">
              <TwitterFollowButton
                screenName={"Podboard100"}
                options={{ size: "large" }}
              />
            </Row>
          </Col>
        </Row>
        {exclusive === "spotify" || typeof exclusive === "undefined" ? (
          <div>
            <hr />
            <Row>
              <iframe
                src={spotify_url}
                width="100%"
                height="232"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title={name}
              ></iframe>
            </Row>
            <hr />
          </div>
        ) : (
          <div>
            <HR />
            {this.state.episodelist.episodes.map((episode, index) => (
              <Col className="mb-3" key={episode.id}>
                <Row className="justify-content-between">
                  <Col xs={10} className="px-0">
                    <H3>{episode.title}</H3>
                  </Col>
                  <Col xs={2}>
                    <div className="alignright">
                      <Link
                        style={{ color: "#ffbe1c", float: "right" }}
                        to={{
                          pathname: `player/${id}`,
                          state: { episodeId: index },
                        }}
                      >
                        <FontAwesomeIcon icon={faPlayCircle} size="2x" />
                      </Link>
                    </div>
                  </Col>
                </Row>
                {episode.summary === null ||
                typeof episode.summary === "undefined" ? null : (
                  <Row>
                    <P>
                      <ReactReadMoreReadLess
                        charLimit={300}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                      >
                        {episode.summary}
                      </ReactReadMoreReadLess>
                    </P>
                  </Row>
                )}
                <Row
                  className="justify-content-between"
                  style={{ color: "#e0e0e0" }}
                >
                  <Moment format="Do MMM YYYY">{episode.published}</Moment>
                  <div>
                    <span className="mr-1">Duration:</span>
                    <span>{episode.duration}</span>
                  </div>
                </Row>
                <HR />
              </Col>
            ))}
          </div>
        )}
        {youtube_playlist_id === null ||
        typeof youtube_playlist_id === "undefined" ? null : (
          <div className="video-container">
            <iframe
              src={youtube_url}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={artworkUrl}
            ></iframe>
          </div>
        )}
        <Row xs={1} md={2} className="my-3">
          <Col>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={twitter_handle}
              options={{ height: 400, width: "100%" }}
              // autoHeight
              // theme="light"
              theme="dark"
              // noHeader
              noBorders
              noFooter
            />
          </Col>
          <Col>
            <TwitterTimelineEmbed
              sourceType="list"
              // screenName="PodBoard100"
              id="1265366976219750401"
              options={{ height: 400, width: "100%" }}
              // autoHeight
              // theme="light"
              theme="dark"
              //noHeader
              noBorders
              noFooter
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

// {Object.keys(this.state.episodelist.social_media.buzz).map(
//   (buzz, i) => (
//     <span key={i}>#{buzz} </span>
//   )
// )}

// Having a slash at the start or not either adds the path or redefines it!!!!
//  pathname: `/${cc}/player/${id}`,
