import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const Player = ({ currentEpisode, setCurrentEpisode, currentPod }) => {
  // Event Handlers
  const skipTrackHandler = (direction) => {
    let currentIndex = currentPod.episodes.findIndex(
      (episode) => episode.id === currentEpisode.id
    );
    if (direction === "skip-forward") {
      // the modulas is there so we go back to zero in the episode array
      setCurrentEpisode(
        currentPod.episodes[(currentIndex + 1) % currentPod.episodes.length]
      );
    }
    if (direction === "skip-back") {
      if ((currentIndex - 1) % currentPod.episodes.length === -1) {
        setCurrentEpisode(currentPod.episodes[currentPod.episodes.length - 1]);
        return;
      }
      // the modulas is there so we go back to zero in the episode array
      setCurrentEpisode(
        currentPod.episodes[(currentIndex - 1) % currentPod.episodes.length]
      );
    }
  };

  return (
    <div className="player-container">
      <div className="audio-player">
        <AudioPlayer
          progressJumpSteps={{
            forward: 15000,
            backward: 5000,
          }}
          src={currentEpisode.enclosure}
          autoPlay
          preload="metadata"
          showSkipControls="true"
          customVolumeControls={[]}
          customAdditionalControls={[]}
          onClickPrevious={() => skipTrackHandler("skip-back")}
          onClickNext={() => skipTrackHandler("skip-forward")}
        />
      </div>
    </div>
  );
};

export default Player;
